import React, { useState } from "react";
import "../styles/Header.css";
import headerLogo from "../assets/headerLogo.png"
import { useNavigate, useLocation } from 'react-router-dom';

function Header({onAboutUsClick, onWWDClick, onGalleryClick, activeValue}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [type, setType] = useState(activeValue || 'home'); 

  const handleButtonClick = (type) => {
    setType(type)
    if (location.pathname === "/") {
      // If already on the homepage, trigger smooth scroll
      if (type === "about") onAboutUsClick();
      else if (type === "wwd") onWWDClick();
      else if (type === "gallery") onGalleryClick();
      else if (type === "contact") navigate("/contact");
    } else {
      if (type === "about") navigate("/?scrollTo=aboutUs");
      else if (type === "wwd") navigate("/?scrollTo=wwd");
      else if (type === "gallery") navigate("/?scrollTo=gallery");
      else if (type === "home") navigate("/");
      else if (type === "contact") navigate("/contact");
    }
  }; 

  return (
    <header className="header">
      <div className="top-bar">
        <p>MAIL ID: theage2025@gmail.com</p>
      </div>
      <div className="nav-bar">
        <div className="header-logo">
          <img className="header-image" src={headerLogo} alt="Logo" />
        </div>
        <nav className="nav-links">
          <a href="#home" className={`${type === 'home' ? 'active' : ''}`} onClick={() => handleButtonClick('home')}>Home</a>
          <a href="#about" className={`${type === 'about' ? 'active' : ''}`} onClick={() => handleButtonClick('about')}>About us</a>
          <a href="#wwd" className={`${type === 'wwd' ? 'active' : ''}`} onClick={() => handleButtonClick('wwd')}>What we do</a>
          <a href="#gallery" className={`${type === 'gallery' ? 'active' : ''}`} onClick={() => handleButtonClick('gallery')}>Gallery</a>
          <a href="#contact" className={`${type === 'contact' ? 'active' : ''}`} onClick={() => handleButtonClick('contact')}>Enquiry</a>
        </nav>
      </div>
    </header>
);
}

export default Header;