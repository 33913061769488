import React, { useState } from 'react';
import '../styles/BookingForm.css'; // Create a CSS file for styles
import emailjs from 'emailjs-com';
const serviceId = "service_6nrhx4q";
const templateId = "template_wll4vtg";
const publicKey = "-PSMjjtRWFMvNYZ5J";

const BookingForm = () => {
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    message: '',
    phone: '',
    eventStartDate: '',
    eventEndDate: '',
    location: '',
    venue: '',
    totalGuest: '',
    servicesNeeded: [],
    decorations: [],
    otherServices: [],
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked
          ? [...prevData[name], value]
          : prevData[name].filter((item) => item !== value),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const convertFormDataToString = (data) => {
    let formString = '';
    for (let key in data) {
      if (Array.isArray(data[key])) {
        formString += `${key}: ${data[key].join(', ')}\n`;
      } else {
        formString += `${key}: ${data[key]}\n`;
      }
    }
    return formString;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formString = convertFormDataToString(formData);
    const updatedFormData = {
      ...formData,
      message: formString, 
    };

    try {
      emailjs
      .send(
        serviceId,
        templateId,
        updatedFormData,
        publicKey
      )
      .then(
        (result) => {
          console.log("Email sent successfully:", result.text);
          alert("Email sent successfully!");
        },
        (error) => {
          console.error("Error sending email:", error.text);
          alert("Failed to send email.");
        }
      );
    } catch (error) {
      console.error("Error:", error);
      alert("Error sending email.");
    }        
  };

  return (
    <div className="booking-form-container">
      <h1>Booking Form</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-groups">
          <div className="form-group">
            <label>Name <span style={{ color: 'red' }}>*</span></label>
            <input
              type="text"
              name="user_name"
              value={formData.user_name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Phone <span style={{ color: 'red' }}>*</span></label>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Email Address <span style={{ color: 'red' }}>*</span></label>
            <input
              type="email"
              name="user_email"
              value={formData.user_email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Event Start Date <span style={{ color: 'red' }}>*</span></label>
            <input
              type="date"
              name="eventStartDate"
              value={formData.eventStartDate}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Event End Date <span style={{ color: 'red' }}>*</span></label>
            <input
              type="date"
              name="eventEndDate"
              value={formData.eventEndDate}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Location <span style={{ color: 'red' }}>*</span></label>
            <input
              type="text"
              name="location"
              value={formData.location}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Venue <span style={{ color: 'red' }}>*</span></label>
            <input
              type="text"
              name="venue"
              value={formData.venue}
              onChange={handleChange}
              required
              className="longInput"
            />
          </div>
          <div className="form-group">
            <label>Total Guest</label>
            <input
              type="number"
              name="totalGuest"
              value={formData.totalGuest}
              onChange={handleChange}
              className="longInput"
            />
          </div>
        </div>

        <h2>Service Needed</h2>
        <div className="checkbox-group services">
          <label>
            <input
              type="checkbox"
              name="servicesNeeded"
              value="Advertisement"
              checked={formData.servicesNeeded.includes('Advertisement')}
              onChange={handleChange}
            />
            Advertisement
          </label>
          <label>
            <input
              type="checkbox"
              name="servicesNeeded"
              value="Gifts"
              checked={formData.servicesNeeded.includes('Gifts')}
              onChange={handleChange}
            />
            Gifts
          </label>
          <label>
            <input
              type="checkbox"
              name="servicesNeeded"
              value="Events"
              checked={formData.servicesNeeded.includes('Events')}
              onChange={handleChange}
            />
            Events
          </label>
        </div>
        <section className='decoration'>
        <h2>Decoration</h2>
        <div className="checkbox-group short">
            <label><input className="column-label"
                  type="checkbox" name="decorations" value="Engagement"
                  checked={formData.decorations.includes('Engagement')} onChange={handleChange}/>
                Engagement
            </label>
            <label><input className="column-label"
                  type="checkbox" name="decorations" value="Reception"
                  checked={formData.decorations.includes('Reception')} onChange={handleChange} />
                Reception
            </label>
            <label><input className="column-label"
                  type="checkbox" name="decorations" value="House warming"
                  checked={formData.decorations.includes('House warming')}
                  onChange={handleChange}/>
                House warming
            </label>
            <label><input className="column-label"
                  type="checkbox" name="decorations" value="Farewell party"
                  checked={formData.decorations.includes('Farewell party')} onChange={handleChange}/>
                Farewell party
            </label>
            <label>
              <input className="column-label"
                  type="checkbox" name="decorations" value="Bangle Ceremony"
                  checked={formData.decorations.includes('Bangle Ceremony')} onChange={handleChange}/>
                Bangle Ceremony
            </label>
            <label><input className="column-label"
                  type="checkbox" name="decorations" value="Corporate Events"
                  checked={formData.decorations.includes('Corporate Events')} onChange={handleChange}/>
                Corporate Events
            </label>
            <label><input className="column-label"
                  type="checkbox" name="decorations" value="Marriage"
                  checked={formData.decorations.includes('Marriage')} onChange={handleChange}/>
                Marriage
            </label>
            <label><input className="column-label"
                  type="checkbox" name="decorations" value="Birthday party"
                  checked={formData.decorations.includes('Birthday party')} onChange={handleChange}/>
                Birthday party
            </label>
            <label><input className="column-label"
                  type="checkbox" name="decorations" value="Baby shower"
                  checked={formData.decorations.includes('Baby shower')} onChange={handleChange}/>
                Baby shower
            </label>
            <label><input className="column-label"
                  type="checkbox" name="decorations" value="Puberty ceremony"
                  checked={formData.decorations.includes('Puberty ceremony')} onChange={handleChange}/>
                Puberty ceremony
            </label>
            <label><input className="column-label"
                  type="checkbox" name="decorations" value="Anniversary"
                  checked={formData.decorations.includes('Anniversary')} onChange={handleChange}/>
                Anniversary
            </label>
            <label><input className="column-label"
                  type="checkbox" name="decorations" value="Others"
                  checked={formData.decorations.includes('Others')} onChange={handleChange}/>
                Others
            </label>
        </div></section>

        <section className='other-services'>
        <h2>Other Services</h2>
        <div className="checkbox-group short">
            <label><input className="column-label"
                  type="checkbox" name="otherServices" value="Dj"
                  checked={formData.otherServices.includes('Dj')}onChange={handleChange} />
                Dj
            </label>
            <label><input className="column-label"
                  type="checkbox" name="otherServices" value="Hospitality"
                  checked={formData.otherServices.includes('Hospitality')} onChange={handleChange}/>
                Hospitality
            </label>
            <label><input className="column-label"
                  type="checkbox" name="otherServices" value="Chandamelam"
                  checked={formData.otherServices.includes('Chandamelam')} onChange={handleChange}/>
                Chandamelam
            </label>
            <label><input className="column-label"
                  type="checkbox" name="otherServices" value="Fireworks"
                  checked={formData.otherServices.includes('Fireworks')} onChange={handleChange} />
                Fireworks
            </label>
            <label><input className="column-label"
                  type="checkbox" name="otherServices" value="Return Gifts"
                  checked={formData.otherServices.includes('Return Gifts')} onChange={handleChange}/>
                Return Gifts
            </label>
            <label><input className="column-label"
                  type="checkbox" name="otherServices" value="Cake"
                  checked={formData.otherServices.includes('Cake')} onChange={handleChange}/>
                Cake
            </label>
            <label><input className="column-label"
                  type="checkbox" name="otherServices" value="Light music"
                  checked={formData.otherServices.includes('Light music')} onChange={handleChange}/>
                Light music
            </label>
            <label><input className="column-label"
                  type="checkbox" name="otherServices" value="Entertainment"
                  checked={formData.otherServices.includes('Entertainment')} onChange={handleChange}/>
                Entertainment
            </label>
            <label><input className="column-label"
                  type="checkbox" name="otherServices" value="Catering"
                  checked={formData.otherServices.includes('Catering')} onChange={handleChange}/>
                Catering
            </label>
            <label><input className="column-label"
                  type="checkbox" name="otherServices" value="Instant photobooth"
                  checked={formData.otherServices.includes('Instant photobooth')} onChange={handleChange}/>
                Instant photobooth
            </label>
            <label><input className="column-label"
                  type="checkbox" name="otherServices" value="Invitational Cards"
                  checked={formData.otherServices.includes('Invitational Cards')} onChange={handleChange}/>
                Invitational Cards
            </label>
            <label><input className="column-label"
                  type="checkbox" name="otherServices" value="Others"
                  checked={formData.otherServices.includes('Others')} onChange={handleChange}/>
                Others
            </label>
        </div></section>

        <button type="submit" className="submit-button">Send Message</button>
      </form>
    </div>
  );
};

export default BookingForm;
