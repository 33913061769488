import React, { useRef, useEffect } from "react";
import "../styles/App.css";
import Headerpage from "./Header";
import Contentpage from "./Content";
import Footerpage from "./Footer";
import RecentActivityPage from "./RecentActivity";
import { useLocation, useNavigate } from "react-router-dom";

function Homepage() {
  const aboutUsRef = useRef(null); 
  const wwdRef = useRef(null);
  const galleryRef = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();

  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const scrollTo = params.get("scrollTo");

    if (scrollTo) {
      if (scrollTo === "aboutUs") scrollToSection(aboutUsRef);
      if (scrollTo === "wwd") scrollToSection(wwdRef);
      if (scrollTo === "gallery") scrollToSection(galleryRef);
      
      navigate("/", { replace: true });
    }
  }, [location, navigate]);

  return (
    <div className="App">
      <Headerpage 
        onAboutUsClick={() => scrollToSection(aboutUsRef)} 
        onWWDClick={() => scrollToSection(wwdRef)} 
        onGalleryClick={() => scrollToSection(galleryRef)} 
      />
      <Contentpage 
        aboutUsRef={aboutUsRef} 
        wwdRef={wwdRef} 
      />
      <RecentActivityPage 
        galleryRef={galleryRef}
      />
      <Footerpage
        onAboutUsClick={() => scrollToSection(aboutUsRef)} 
        onWWDClick={() => scrollToSection(wwdRef)} 
        onGalleryClick={() => scrollToSection(galleryRef)} 
      />
    </div>
  );
}

export default Homepage;
