import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "./styles/App.css";
import Homepage from "./components/Homepage";
import Giftspage from "./components/Gifts";
import Eventspage from "./components/Events";
import Adspage from "./components/Ads";
import Contactspage from "./components/Contact";

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/gifts" element={<Giftspage />} />
        <Route path="/events" element={<Eventspage />} />
        <Route path="/ads" element={<Adspage />} />
        <Route path="/contact" element={<Contactspage />} />
      </Routes>
    </Router>
  );
}

export default App;
