import React, { useEffect, useState } from "react";
import "../styles/Content.css";
import homeGroup from "../assets/homeGroup.png"
import aboutGroup from "../assets/aboutGroup.png"
import aboutImage2 from "../assets/aboutImage2.png"
import afterAbout1 from "../assets/afterAbout1.png"
import afterAbout2 from "../assets/afterAbout2.png"
import afterAbout3 from "../assets/afterAbout3.png"
import wwrGroup2 from "../assets/wwrGroup2.png"
import downArrow from "../assets/arrowDown.png"
import circle1 from "../assets/Ellipse1.png"
import circle2 from "../assets/Ellipse2.png"
import circle3 from "../assets/Ellipse3.png"
import { aboutTeamScrollEffect } from './run.js';
import { useNavigate, useLocation } from 'react-router-dom';

const ContentPage = ({aboutUsRef, wwdRef}) => {
  const navigate = useNavigate();
 
  useEffect(() => {
    aboutTeamScrollEffect();

    return () => {
      // Cleanup: Remove the event listener when the component unmounts
      const aboutTeam = document.querySelector('.about-team');
      if (aboutTeam) {
        aboutTeam.removeEventListener('scroll', aboutTeamScrollEffect);
      }
    };
  }, []);

  const handleHomeEvents = (type) => {
    if (type === "event") navigate("/events")
    else if (type === "gift") navigate("gifts")
    else if (type === "ad") navigate("ads")
  }

  const location = useLocation();
  const [type, setType] = useState('home');
  const handleButtonClick = (type) => {
    setType(type)
    if (location.pathname === "/") {
      // If already on the homepage, trigger smooth scroll
      if (type === "contact") navigate("/contact");
       else if (type === "contact") navigate("/contact");
    }
  }

  return (
    <div className="content-page">
      {/* Hero Section */}
      <div className="hero-section text-center py-5 bg-light">
        <h1 className="fw-bold">Your Vision, Our Execution</h1>
        <div className="button-group-content mt-4">
          <button className="btn btn-primary mx-2" onClick={() => handleHomeEvents("ad")}>Advertisement</button>
          <button className="btn btn-primary mx-2" onClick={() => handleHomeEvents("gift")}>Gifts</button>
          <button className="btn btn-primary mx-2" onClick={() => handleHomeEvents("event")}>Events</button>
        </div>
      </div>

      {/* What We Give Section */}
      <div className="what-we-give-section text-center">
        <h2>What We Give</h2>
        <div className="features mt-4">
          <img src={homeGroup} alt="Memorable" className="img-fluid feature-group"/>
        </div>
      </div>

      {/* About Us Section */}
      <div ref={aboutUsRef} className="">
        <img alt="" className="img-fluid  mx-auto about-image" src={aboutImage2}/>
      </div>

      <div className="about-us-container">
      <div className="row align-items-center about-us-content ">
        {/* Text Section */}
        <div className="text-section">
          <h2>About Us</h2>
          <h1 className="fw-bold">Who We Are?</h1>
          <p className="subtitle">Advertisement / Gifts / Events</p>
          <p className="description">
            With over 15 years of expertise, The AGE Advertisement, Gift, and
            Event Management Company has successfully brought visions to life for
            esteemed clients, including KOB, Sahana Mill, KPR, Phoenix,
            Parryware, etc..
          </p>
          <div className="buttons mt-4">
            <button id="contact-btn" className={`btn btn-outline-primary ${type === "contact" ? "active" : ""} me-2`}
              onClick={() => handleButtonClick("contact")}>Contact</button>
            <button className="book-now-btn" onClick={() => navigate("/contact")}>Book Now</button>
          </div>
        </div>
        {/* Image Section */}
        <div className="text-center images-section">
          <img src={aboutGroup} alt="Event" className="img-fluid about-group-image"/>
        </div>
      </div>
    </div>

      {/* After About Section */}
      <div className="after-about">
        <div className="image-container">
          <span className="letter">A</span>
          <img src={afterAbout1} alt="Event" className="after-about-image1"/>
        </div>
        <div className="image-container">
          <span className="letter">G</span>
          <img src={afterAbout2} alt="Event" className="after-about-image2"/>
        </div>
        <div className="image-container">
          <span className="letter">E</span>
          <img src={afterAbout3} alt="Event" className="after-about-image3"/>
        </div>
      </div>

      {/* Who We Are Section */}
      <div className="who-we-are">
        <div className="who-we-are-images">
          <img src={wwrGroup2} alt="Event" className="wwr-image img-fluid" />
        </div>
        <h3 className="text-center">Dedicated Service, Heartfelt Commitment!</h3>
        <button className="wwr-b">Who We Are?</button>
      </div>

      {/* What We Do Section */}
      <div className="what-we-do" ref={wwdRef}>
        <div className="d-flex flex-wrap">
        <div className="wwd">
          <img src={downArrow} alt="Advertisement Management Service" />
          <h2>WHAT WE DO?</h2>
        </div>
        <div className="content">
          <div className="item first">
            <p>Advertisement Management Service</p>
            <img src={circle1} alt="Advertisement Management Service" />
            <span>A</span>
          </div>
          <section className="item_gimg">     
                 <div className="item_g"><span>G</span></div>
          <div className="item second">
            <p>Gift Management Service</p>
            <img src={circle2} alt="Gift Management Service"/>
          </div>
          </section>
          <div className="item first">
            <p>Event Management Service</p>
            <img src={circle3} alt="Event Management Service" / >
            <span>E</span>
          </div>
        </div>
        </div>
      </div>

       {/* About Team Section */}
      <div className="about-team text-center">
        <h1>Quality Strengthened Our Work</h1>
        <div className="about-team-group m-4">
          <button className=" mx-2">Experience</button>
          <button className=" mx-2">Team Size</button>
          <button className=" mx-2">Clients</button>
        </div>
        <div className="about-team-count">
          <span className="d-inline-block mx-3">15+</span>
          <span className="d-inline-block mx-3">150+</span>
          <span className="d-inline-block mx-3">200+</span>
        </div>
      </div>
</div>
  );
};

export default ContentPage;
