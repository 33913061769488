import React, { useRef, useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "../styles/Gifts.css";
import Headerpage from "./Header";
import Footerpage from "./Footer";
import GiftsImage from "../assets/GiftsImage.png";
import loveImage from "../assets/love.png";
import celebrationImage from "../assets/Celebration.png";
import anniversary from "../assets/Categories/Anniversary.png";
import wedding from "../assets/Categories/Wedding.png";
import valentine from "../assets/Categories/Valentine.png";
import birthday from "../assets/Categories/Birthday.png";
import engagement from "../assets/Categories/Engagement.png";
import baby_shower from "../assets/Categories/Baby_shower.png";
import graduation from "../assets/Categories/Graduation.png";
import corporate from "../assets/Categories/Corporate.png";
import festive from "../assets/Categories/Festive.png";
import housewarming from "../assets/Categories/Housewarming.png";
import { useNavigate  } from 'react-router-dom';

const Gifts = () => {
    const navigate = useNavigate();
    const valentinegiftsRef = useRef(null);
    const engagementgiftsRef = useRef(null);
    const anniversarygiftsRef = useRef(null);
    const weddinggiftsRef = useRef(null);
    const birthdaygiftsRef = useRef(null);
    const corporategiftsRef = useRef(null);
    const housewarmingGiftsRef = useRef(null);
    const festivalgiftsRef = useRef(null);
    const graduationgiftsRef = useRef(null);

    const events = [
        { icon: anniversary, title: "Anniversary Gifts" },
        { icon: wedding, title: "Wedding Gifts" },
        { icon: valentine, title: "Valentine's Day Gifts" },
        { icon: birthday, title: "Birthday Gifts" },
        { icon: engagement, title: "Engagement Gifts" },
        { icon: baby_shower, title: "Baby Shower Gifts" },
        { icon: graduation, title: "Graduation Gifts" },
        { icon: corporate, title: "Corporate Gifts" },
        { icon: festive, title: "Festive Gifts" },
        { icon: housewarming, title: "Housewarming Gifts" },
      ];

      const [isAtEnd, setIsAtEnd] = useState(false); // Track whether at the end
      const scrollable = (ref) => {
        if (ref.current) {
            const { scrollLeft, scrollWidth, clientWidth } = ref.current;
            if (scrollLeft + clientWidth <= scrollWidth && isAtEnd) {
                // If reached the right end, scroll left
                ref.current.scrollBy({ left: -200, behavior: 'smooth' });
                setIsAtEnd(true); // Change to scroll right symbol
                if(scrollLeft - 200 <= 0) setIsAtEnd(false)
            } else if (scrollLeft === 0) {
                // If at the left start, scroll right
                ref.current.scrollBy({ left: 200, behavior: 'smooth' });
                setIsAtEnd(false); // Change to scroll right symbol
            } else {
                // Default scroll right
                ref.current.scrollBy({ left: 200, behavior: 'smooth' });
                // Check if this action will reach the end
                if (scrollLeft + clientWidth + 200 >= scrollWidth) {
                    setIsAtEnd(true); // Change to scroll left symbol
                }
            }}
        };

      const giftsData = [
        {
            title: "Valentine's Day Gifts", ref: valentinegiftsRef ,
            data: [
                {id: 1, image: require("../assets/Categories/Valentine's_1.png"), alt:"Valentine's_gifts_1", title:"Love Message Jar"},
                {id: 2, image: require("../assets/Categories/Valentine's_2.png"), alt:"Valentine's_gifts_2", title:"Heart-Shaped Keychains"},
                {id: 3, image: require("../assets/Categories/Valentine's_3.png"), alt:"Valentine's_gifts_3", title:"Mini Succulent Plants"},
                {id: 4, image: require("../assets/Categories/Valentine's_4.png"), alt:"Valentine's_gifts_4", title:"Love Coupons Booklet"},
                {id: 5, image: require("../assets/Categories/Valentine's_5.png"), alt:"Valentine's_gifts_5", title:"Chocolate Gift Packs"},
                {id: 6, image: require("../assets/Categories/Valentine's_6.png"), alt:"Valentine's_gifts_6", title:"Romantic Greeting Cards"}, 
            ]
        },
        {
            title: "Engagement Gifts", ref: engagementgiftsRef,
            data: [
                {id: 1, image: require("../assets/Categories/Engagement_1.png"), alt:"Engagement_1", title:"Keepsake Box"},
                {id: 2, image: require("../assets/Categories/Engagement_2.png"), alt:"Engagement_2", title:"Monogrammed Towels"},
                {id: 3, image: require("../assets/Categories/Engagement_3.png"), alt:"Engagement_3", title:"Ring Holder"},
                {id: 4, image: require("../assets/Categories/Engagement_4.png"), alt:"Engagement_4", title:"Personalized ThrowBlankets"},
                {id: 5, image: require("../assets/Categories/Engagement_5.png"), alt:"Engagement_5", title:"Couple's Coffee Mugs"},
                {id: 6, image: require("../assets/Categories/Engagement_6.png"), alt:"Engagement_6", title:"Love Story Scrapbook Kit"}, 
            ]
        },
        {
            title: "Wedding Gifts", ref: weddinggiftsRef,
            data: [
                {id: 1, image: require("../assets/Categories/Wedding_1.png"), alt:"Wedding_1", title:"Photo Frame"},
                {id: 2, image: require("../assets/Categories/Wedding_2.png"), alt:"Wedding_2", title:"Heart-Shaped Keychains"},
                {id: 3, image: require("../assets/Categories/Wedding_3.png"), alt:"Wedding_3", title:"Couple's Keychains"},
                {id: 4, image: require("../assets/Categories/Wedding_4.png"), alt:"Wedding_4", title:"Wed-Themed Coffee Mugs"},
                {id: 5, image: require("../assets/Categories/Wedding_5.png"), alt:"Wedding_5", title:"Memory Jar"},
                {id: 6, image: require("../assets/Categories/Wedding_6.png"), alt:"Wedding_6", title:"Wooden Coasters"}, 
            ]
        },
        {
            title: "Anniversary Gifts", ref: anniversarygiftsRef,
            data: [
                {id: 1, image: require("../assets/Categories/Anniversary_1.png"), alt:"Anniversary_1", title:"Customized Bracelet"},
                {id: 2, image: require("../assets/Categories/Anniversary_2.png"), alt:"Anniversary_2", title:"Heart Star Map"},
                {id: 3, image: require("../assets/Categories/Anniversary_3.png"), alt:"Anniversary_3", title:"Personalized Clock"},
                {id: 4, image: require("../assets/Categories/Anniversary_4.png"), alt:"Anniversary_4", title:"Glow Lamp"},
                {id: 5, image: require("../assets/Categories/Anniversary_5.png"), alt:"Anniversary_5", title:"Couple's Coffee Mugs"},
                {id: 6, image: require("../assets/Categories/Anniversary_6.png"), alt:"Anniversary_6", title:"Couples Watch Set"}, 
            ]
        },
        {
            title: "Birthday Gifts", ref: birthdaygiftsRef,
            data: [
                {id: 1, image: require("../assets/Categories/Birthday_1.png"), alt:"Birthday_1", title:"Customized Keychains"},
                {id: 2, image: require("../assets/Categories/Birthday_2.png"), alt:"Birthday_2", title:"Compact Mirrors"},
                {id: 3, image: require("../assets/Categories/Birthday_3.png"), alt:"Birthday_3", title:"Birthday-Themed Mugs"},
                {id: 4, image: require("../assets/Categories/Birthday_4.png"), alt:"Birthday_4", title:"Mini Journals or Diaries"},
                {id: 5, image: require("../assets/Categories/Birthday_5.png"), alt:"Birthday_5", title:"Lip Balm Gift Sets"},
                {id: 6, image: require("../assets/Categories/Birthday_6.png"), alt:"Birthday_6", title:"Birthday Scratch Cards"}, 
            ]
        },
        {
            title: "Corporate Gifts", ref: corporategiftsRef,
            data: [
                {id: 1, image: require("../assets/Categories/Corporate_1.png"), alt:"Corporate_1", title:"Customized Notebooks"},
                {id: 2, image: require("../assets/Categories/Corporate_2.png"), alt:"Corporate_2", title:"High-Quality Pens"},
                {id: 3, image: require("../assets/Categories/Corporate_3.png"), alt:"Corporate_3", title:"Gift Cards"},
                {id: 4, image: require("../assets/Categories/Corporate_4.png"), alt:"Corporate_4", title:"Wireless Earbuds"},
                {id: 5, image: require("../assets/Categories/Corporate_5.png"), alt:"Corporate_5", title:"Corporate Apparel"},
                {id: 6, image: require("../assets/Categories/Corporate_6.png"), alt:"Corporate_6", title:"Desk Plants"}, 
            ]
        },
        {
            title: "Housewarming Gifts", ref: housewarmingGiftsRef,
            data: [
                {id: 1, image: require("../assets/Categories/Housewarming_1.png"), alt:"Housewarming_1", title:"Indoor Plants"},
                {id: 2, image: require("../assets/Categories/Housewarming_2.png"), alt:"Housewarming_2", title:"Welcome Mats"},
                {id: 3, image: require("../assets/Categories/Housewarming_3.png"), alt:"Housewarming_3", title:"Custom Wall Art"},
                {id: 4, image: require("../assets/Categories/Housewarming_4.png"), alt:"Housewarming_4", title:"Wall Clocks"},
                {id: 5, image: require("../assets/Categories/Housewarming_5.png"), alt:"Housewarming_5", title:"Outdoor Decor Items"},
                {id: 6, image: require("../assets/Categories/Housewarming_6.png"), alt:"Housewarming_6", title:"Glass Dinnerware"}, 
            ]
        },
        {
            title: "Festival Gifts", ref: festivalgiftsRef,
            data: [
                {id: 1, image: require("../assets/Categories/Festival_1.png"), alt:"Festival_1", title:"Home Decor Items"},
                {id: 2, image: require("../assets/Categories/Festival_2.png"), alt:"Festival_2", title:"Decorative Lights"},
                {id: 3, image: require("../assets/Categories/Festival_3.png"), alt:"Festival_3", title:"Gift Cards"},
                {id: 4, image: require("../assets/Categories/Festival_4.png"), alt:"Festival_4", title:"Traditional Crafts"},
                {id: 5, image: require("../assets/Categories/Festival_5.png"), alt:"Festival_5", title:"Creative Lily"},
                {id: 6, image: require("../assets/Categories/Festival_6.png"), alt:"Festival_6", title:"Festive Apparel"},  
            ]
        },
        {
            title: "Graduation Gifts", ref: graduationgiftsRef,
            data: [
                {id: 1, image: require("../assets/Categories/Graduation_1.png"), alt:"Graduation_1", title:"Memory Box"},
                {id: 2, image: require("../assets/Categories/Graduation_2.png"), alt:"Graduation_2", title:"Personalized Keychains"},
                {id: 3, image: require("../assets/Categories/Graduation_3.png"), alt:"Graduation_3", title:"Time Capsule Kit"},
                {id: 4, image: require("../assets/Categories/Graduation_4.png"), alt:"Graduation_4", title:"Personalized Frame"},
                {id: 5, image: require("../assets/Categories/Graduation_5.png"), alt:"Graduation_5", title:"Photo Books"},
                {id: 6, image: require("../assets/Categories/Graduation_6.png"), alt:"Graduation_6", title:"Digital Scrapbook"},  
            ]
        },
      ];
    
    return(
        <div className="App">
        <Headerpage/>
        <div className="text-center book_now">
            <h1>Gift Management</h1>
            <div className="button-group">
                <button className="btn btn-primary" onClick={() => navigate("/contact")}>Book Now</button>
            </div>
        </div>

        <div className="text-center my-5 above_gifts">
            <h1 className="fw-bold">The AGE Gifts Management</h1>
            <img src={GiftsImage} alt="gifts_image" className="img-fluid"/>
        </div>

        <div className="container customized-gifts-planning ">
            <div className="row">
                <div className="gifts-grid2">
                {events.map((event, index) => (
                    <div className="gifts-item2 " key={index}>
                        <div className="gifts-icon-container">
                            <img src={event.icon} alt={event.title} className="img-fluid gifts-icon"/>
                        </div>
                        <p className="gifts-title2">{event.title}</p>
                    </div>
                ))}
                </div>
            </div>
        </div>

        <section className="bg-light pl-4 above_category">
            <div className="container d-flex align-items-center px-5">
                <div className="col-md-5 content_section">
                    <img src={loveImage} alt="love_icon" className="img-fluid love_image"/>
                    <p className="mt-3 text-start">
                        THE AGE ensures every gift delights our customers,
                        creating unforgettable moments of joy.
                    </p>
                </div>
                <div className="col-md-5">
                    <img src={celebrationImage} alt="celebration_image" className="img-fluid celebration_image"/>     
                </div>
            </div>
        </section>

        {giftsData.map((category, idx) => (
            <div className="container my-4" key={idx}>
                <div className="d-flex justify-content-between align-items-center mb-3 gifts-section">
                    <h2 className="fs-1">{category.title}</h2>
                    <button className="btn scroll-button fs-3" onClick={() => scrollable(category.ref)}>{isAtEnd ? "<" : ">"}
                       
                    </button>
                </div>
                <div className="catergory_gifts_grid" ref={category.ref}>
                    {category.data.map((card) => (
                        <div key={card.id} className="category_gifts_card" >
                            <img src={card.image} className=" gifts_image" alt={card.alt}/>
                            <div className="card-body text-center">
                                <p className="card-text text-center fw-bold"style={{fontSize:14}}>{card.title}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        ))}
        
        <div className="text-white p-4 contact_us">
            <p>For more customized creative Gifts, Connect with us!</p>
            <div className="contact_button">
                <button onClick={() => navigate("/contact")}>
                    Contact Us
                </button>
            </div>
        </div>
        <Footerpage/>
        </div>
    );
}

export default Gifts;
