import React from "react";
import "../styles/Footer.css";
import footerImg1 from "../assets/footer1.png"
import footerImg2 from "../assets/footer2.png"
import footerImg3 from "../assets/footer3.png"
import footerLogo from "../assets/footerLogo.png"
import socialImage from "../assets/social.png"
import callImage from "../assets/call.png"
import locationImage from "../assets/location.png"
import { useNavigate, useLocation } from 'react-router-dom';

function Footer({onAboutUsClick, onWWDClick, onGalleryClick}) {
  const navigate = useNavigate();
  const location = useLocation();
  
  const handleButtonClick = (type) => {
    if (location.pathname === "/") {
      if (type === "home") navigate("/");
      else if (type === "about") onAboutUsClick();
      else if (type === "service") onWWDClick();
      else if (type === "event") {
        navigate("/events");
        window.scrollTo({ top: 0, behavior: "smooth" })
      }
      else if (type === "gallery") onGalleryClick();
      else if (type === "contact") navigate("/contact");
    } else {
      if (type === "home") navigate("/");
      else if (type === "about") navigate("/?scrollTo=aboutUs");
      else if (type === "service") navigate("/?scrollTo=wwd");
      else if (type === "event") navigate("/events");
      else if (type === "gallery") navigate("/?scrollTo=gallery");
      else if (type === "contact") navigate("/contact");
    }
  };

  return (
    <footer className="footer">
        <div className="footer-container">
          <div className="footer-about">
            <img src={footerLogo} alt="Company Logo" className="footer-logo" />
            <p>
              The AGE Company, based in Coimbatore, has been a leading provider of
              advertising, gift solutions, and event organizing services since
              2010, bringing over 15 years of experience to the industry. With a
              strong track record.
            </p>
          </div>
          <section className="footer-social">
          <div className="social-container">
              <img alt="" src={socialImage} className="social-image"></img>
          </div>
          <div className="footer-section">
          <div className="footer-links">
            <h3>Quick Links</h3>
            <ul>
              <li><svg viewBox="0 0 10 10"><polygon points="0,0 10,5 0,10" fill="#FF9800" /></svg><a href="#home" onClick={() => handleButtonClick('home')}>Home</a></li>
              <li><svg viewBox="0 0 10 10"><polygon points="0,0 10,5 0,10" fill="#FF9800" /></svg><a href="#about" onClick={() => handleButtonClick('about')}>About us</a></li>
              <li><svg viewBox="0 0 10 10"><polygon points="0,0 10,5 0,10" fill="#FF9800" /></svg><a href="#Service" onClick={() => handleButtonClick('service')}>Service</a></li>
              <li><svg viewBox="0 0 10 10"><polygon points="0,0 10,5 0,10" fill="#FF9800" /></svg><a href="#event" onClick={() => handleButtonClick('event')} >Event</a></li>
              <li><svg viewBox="0 0 10 10"><polygon points="0,0 10,5 0,10" fill="#FF9800" /></svg><a href="#Gallery" onClick={() => handleButtonClick('gallery')}>Gallery</a></li>
              <li><svg viewBox="0 0 10 10"><polygon points="0,0 10,5 0,10" fill="#FF9800" /></svg><a href="#contact" onClick={() => handleButtonClick('contact')}>Contact</a></li>
            </ul>
          </div>
          <div className="footer-services">
            <h3>The AGE Service</h3>
            <ul>
              <li><img className="footerImage" alt="" src={footerImg1}></img></li>
              <li className="service-content">ADs Management</li>
              <li><img className="footerImage" alt="" src={footerImg2}></img></li>
              <li className="service-content">Gifts Management</li>
              <li><img className="footerImage" alt="" src={footerImg3}></img></li>
              <li className="service-content">Event Management</li>
              </ul>
          </div>
          <div className="footer-contact">
            <h3>Contact Us</h3>
            <ul>
              <li><img className="contactImage-1" alt="" src={callImage}></img> +91 81202 02225, +91 78711 22225</li>
              <li><img className="contactImage-2" alt="" src={locationImage}></img> 8, Badhmavathi Layout, Chinnavedampatty, Coimbatore - 641049</li>
            </ul>
          </div>
          </div>
          </section>
        </div>
        <div className="footer-copyright">
          <p>
            Copyright © 2024 Theage All rights reserved
          </p>
        </div>
      </footer>
      );
    }
    
    export default Footer;