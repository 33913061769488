import React from "react";
import "../styles/RecentActivity.css";
import aboutImage from "../assets/aboutImage.png"

function RecentActivity({galleryRef}) {
  const imageLoop = [1, 2, 3]

  const sectionOne = [
    { image: require("../assets/RecentActivity/1.png") },
    { image: require("../assets/RecentActivity/2.png") },
    { image: require("../assets/RecentActivity/3.png") },
    { image: require("../assets/RecentActivity/4.png") },
    { image: require("../assets/RecentActivity/5.png") },
    { image: require("../assets/RecentActivity/6.png") },
    { image: require("../assets/RecentActivity/7.png") },
    { image: require("../assets/RecentActivity/8.png") },
    { image: require("../assets/RecentActivity/9.png") },
  ];

  const sectionTwo = [
    { image: require("../assets/RecentActivity/10.png") },
    { image: require("../assets/RecentActivity/11.png") },
    { image: require("../assets/RecentActivity/12.png") },
    { image: require("../assets/RecentActivity/13.png") },
    { image: require("../assets/RecentActivity/14.png") },
    { image: require("../assets/RecentActivity/15.png") },
    { image: require("../assets/RecentActivity/16.png") },
    { image: require("../assets/RecentActivity/17.png") },
    { image: require("../assets/RecentActivity/18.png") },
  ];

  return (
    <div className="recent_activity" ref={galleryRef}>
      <img src={aboutImage} alt="Event" className="recent-aboutImage"/>
      <h3 className="recenth3">Recent Activity</h3>
      <div className="image-recentactivity">
        <div className="section1">
          {
            imageLoop.map((_, outerIndex) => (
              sectionOne.map((category, index) => (
                <div className="inside-section1" key={`${outerIndex}-${index}`}>
                  <img src={category.image} alt="Event" className="recent-image"/>
                </div>
              ))
            ))
          }
        </div>
        <div className="section2">
          {
            imageLoop.map((_, outerIndex) => (
              sectionTwo.map((category, index) => (
                <div className="inside-section2" key={`${outerIndex}-${index}`}>
                  <img src={category.image} alt="Event" className="recent-image"/>
                </div>
              ))
            ))
          }
        </div>
      </div>
    </div>
);
}

export default RecentActivity;