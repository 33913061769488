import React, { useState, useEffect } from "react";
import "../styles/Ads.css";
import Headerpage from "./Header";
import Footerpage from "./Footer";
import adsArrow from "../assets/Ads/adsArrow.png";
import rightTick from "../assets/Ads/tick.png";
import { useNavigate } from "react-router-dom";

const Events = () => {
    const navigate = useNavigate();
    const [adType, setAdType] = useState('Offline'); 
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleButtonClick = (button) => {
        setAdType(button); // Update the active button
    };    

    const reviews = [
        { title: "Work", review: 'Quality' },
        { title: "Service", review: 'Impactful' },
        { title: "Payment", review: "Affordable" },
        { title: "Manpower", review: 'Expert' },
        { title: "Commiting", review: 'Satisfied' },
        { title: "Results", review: 'Targeted' }
    ];

    const offlineAds = [
        { image: require("../assets/Ads/auto.png"), title: "Auto Advertisement" },
        { image: require("../assets/Ads/car.png"), title: "Car Advertisement" },
        { image: require("../assets/Ads/bus.png"), title: "Bus Advertisement" },
        { image: require("../assets/Ads/parking.png"), title: "No-Parking Board Advertising" },
        { image: require("../assets/Ads/umbrella.png"), title: "Branded Umbrella Promotion" },
        { image: require("../assets/Ads/board.png"), title: "Standee Advertising" },
        { image: require("../assets/Ads/mall.png"), title: "Mall Advertising" },
        { image: require("../assets/Ads/itpark.png"), title: "IT Park Advertising" },
        { image: require("../assets/Ads/marathan.png"), title: "Marathon Sponsorship" },
        { image: require("../assets/Ads/broucher.png"), title: "Broucher Distribution" },
        { image: require("../assets/Ads/walker.png"), title: "Look Walker" },
        { image: require("../assets/Ads/productSample.png"), title: "Product Sampling" },
        { image: require("../assets/Ads/triCycle.png"), title: "Tri Cycle Promotion" },
        { image: require("../assets/Ads/board.png"), title: "Flex Board Promotions" },
        { image: require("../assets/Ads/print.png"), title: "Print Media ADs" },
        { image: require("../assets/Ads/posters.png"), title: "Posters and Flyers" },
        { image: require("../assets/Ads/tradeShows.png"), title: "Trade Shows and Exhibitions" },
        { image: require("../assets/Ads/wall.png"), title: "Wall and Building Wraps" },
    ];
    
    const onlineAds = [
        { image: require("../assets/Ads/social.png"), title: "SocialMedia Marketing" },
        { image: require("../assets/Ads/google.png"), title: "Google ADs" },
        { image: require("../assets/Ads/youtube.png"), title: "Youtube Collaborations" },
        { image: require("../assets/Ads/chatbot.png"), title: "Chatbot Marketing" },
        { image: require("../assets/Ads/search.png"), title: "Search Engine Optimization" },
        { image: require("../assets/Ads/podcast.png"), title: "Podcast ADs" },
        { image: require("../assets/Ads/email.png"), title: "Email Marketing" },
        { image: require("../assets/Ads/interactive.png"), title: "Interactive ADs" },
        { image: require("../assets/Ads/influencer.png"), title: "Influencer Marketing" },
        { image: require("../assets/Ads/shopAds.png"), title: "Shoppable ADs" },
        { image: require("../assets/Ads/live.png"), title: "Live Streaming ADs" },
        { image: require("../assets/Ads/videoAds.png"), title: "Video ADs" },
    ];

    const offlineAdContent = [
        { image: require("../assets/Ads/offline1.png"), title: "Offline Advertisement", content: "Offline Ads create a tangible and personal connection with your audience, enhancing brand trust and visibility through direct, real-world engagement." },
        { image: require("../assets/Ads/offline2.png"), title: "Benefits - Offline ADs", content: "Local Targeting > Trust and Credibility > Tangible Connection > Continuous Visibility" },
        { image: require("../assets/Ads/offline3.png"), title: "Why to Choose ?", content: "Offline ads build trust, reach locally, and create lasting impact!" },
    ]

    const onlineAdContent = [
        { image: require("../assets/Ads/online1.png"), title: "Online Advertisement", content: "Online marketing efficiently and affordably boosts global reach, visibility, and engagement through targeted campaigns" },
        { image: require("../assets/Ads/online2.png"), title: "Benefits - Online ADs", content: "Global Reach > Cost-Effectiveness > Targeted Campaigns >  Real-Time Tracking and Adjustment" },
        { image: require("../assets/Ads/online3.png"), title: "Why to Choose ?", content: "Online ads offer global reach, targeted engagement, and measurable results!" },
    ]

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? offlineAdContent.length - 1 : prevIndex - 1
        );
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
        prevIndex === offlineAdContent.length - 1 ? 0 : prevIndex + 1
        );
    };

    useEffect(() => {
        const interval = setInterval(() => {
          handleNext();
        }, 3000);
    
        return () => {
          clearInterval(interval);
        };
    }, []);

    return(
        <div className="App">
        <Headerpage/>
        <div className="hero-ads">
            <h1>Advertisement Management</h1>
            <div className="button-group">
                <button onClick={() => navigate("/contact")}>Book Now</button>
            </div>
        </div>
        <div className="ads-content">
            <h1>The AGE ADs Management</h1>
            <img alt="eventBorder" src={adsArrow} className="eventBorder"></img>
        </div>
        <div className="ads-review">
            {reviews.map((category, index) => (
                <div className="ads-item" key={index}>
                    <div className="ads-title"><h3>{category.title}</h3></div>
                    <div className="ads-details">
                    <h3 className="ads-value">{category.review}</h3>
                    <span><img alt="right-tick" src={rightTick} className="right-tick"></img></span>
                    </div>
                </div>
            ))}
        </div>
        <div className="advertisement-page">
            <h1>Our Advertisement</h1>
            <div className="ad-buttons">
                <button className={`ad-btn ${adType === 'Offline' ? 'active' : ''}`} onClick={() => handleButtonClick('Offline')}>Offline ADs</button>
                <button className={`ad-btn ${adType === 'Online' ? 'active' : ''}`} onClick={() => handleButtonClick('Online')}>Online ADs</button>
            </div>
            
             <div className="ad-banners">
                <div>
                    <button className="scroll-button-left" onClick={handlePrev}>&#8249;</button>
                </div>
                <div className="offline-ad-banner">
                {adType === "Offline" ? (
                    <div className="ad-content">
                        <img src={offlineAdContent[currentIndex].image} alt="Offline Ad" className="ad-image" />
                        <div className="ad-text">
                            <h2>{offlineAdContent[currentIndex].title}</h2>
                            <p>
                            {offlineAdContent[currentIndex].content}
                            </p>
                            <button className="book-now-btn-ad" onClick={() => navigate("/contact")}>Book Now</button>
                        </div>
                    </div>
                ) : (
                    <div className="ad-content">
                        <img src={onlineAdContent[currentIndex].image} alt="Offline Ad" className="ad-image" />
                        <div className="ad-text">
                            <h2>{onlineAdContent[currentIndex].title}</h2>
                            <p>
                            {onlineAdContent[currentIndex].content}
                            </p>
                            <button className="book-now-btn-ad" onClick={() => navigate("/contact")}>Book Now</button>
                        </div>
                    </div>
                )}
                </div>
                <div>
                    <button className="scroll-button-right" onClick={handleNext}>&#8250;</button>
                </div>
            </div> 

            <div className="ad-categories">
            {adType === "Offline" ? (
                <div className="ad-grid">
                {offlineAds.map((category, index) => (
                    <div className="ad-item" key={index}>
                    <p className="ad-title">{category.title}</p>
                    <img src={category.image} alt={category.title} className="ad-image" />
                    </div>
                ))}
                </div>
            ) : (
                <div className="ad-grid">
                {onlineAds.map((category, index) => (
                    <div className="ad-item" key={index}>
                    <p className="ad-title">{category.title}</p>
                    <img src={category.image} alt={category.title} className="ad-image" />
                    </div>
                ))}
                </div>
            )}
            </div>

            <div className="ad-final">
                    <p>Your Search, it's us "The AGE"</p>
                    <button onClick={() => navigate("/contact")}>Contact Us</button>
            </div>
        </div>
        <Footerpage/>
        </div>
    );
}

export default Events;