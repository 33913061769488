export const aboutTeamScrollEffect = () => {
    const aboutTeam = document.querySelector('.about-team');
  
    if (aboutTeam) {
      aboutTeam.addEventListener('scroll', () => {
        const scrollTop = aboutTeam.scrollTop;
        aboutTeam.style.backgroundPositionY = `${scrollTop / 2}px`;
      });
    }
  };
  