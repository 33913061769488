import React, {useEffect} from "react";
import "../styles/Events.css";
import Headerpage from "./Header";
import Footerpage from "./Footer";
import RecentActivity from "./RecentActivity";
import eventBorder from "../assets/eventBorder.png";
import { useNavigate } from 'react-router-dom';

const Events = () => {
    const navigate = useNavigate();

    const categories = [
        { image: require("../assets/Events/wedding.png"), title: 'Wedding Planner' },
        { image: require("../assets/Events/corporate.png"), title: 'Corporate Events' },
        { image: require("../assets/Events/bday.png"), title: "Birthday Party's" },
        { image: require("../assets/Events/dj.png"), title: 'Wedding DJ' },
        { image: require("../assets/Events/product.png"), title: 'Product Launches' },
        { image: require("../assets/Events/social.png"), title: 'Social Events' },
        { image: require("../assets/Events/celebrity.png"), title: 'Celebrity events' },
        { image: require("../assets/Events/farewell.png"), title: 'Farewell Parties' },
        { image: require("../assets/Events/anniversary.png"), title: 'Anniversary Party' },
        { image: require("../assets/Events/getto.png"), title: "Get Togethers Party's" },
        { image: require("../assets/Events/special.png"), title: 'Wedding Special Effects' },
        { image: require("../assets/Events/puberty.png"), title: 'Puberty Ceremony' },
        { image: require("../assets/Events/party.png"), title: 'DJ Party' },
        { image: require("../assets/Events/photo.png"), title: 'Photography' },
        { image: require("../assets/Events/video.png"), title: "Videography" }
      ];
      const events = [
        { icon: require("../assets/Events/melam.png"), title: "Mela vathiyam" },
        { icon: require("../assets/Events/catering.png"), title: "Catering" },
        { icon: require("../assets/Events/dance.png"), title: "Dance" },
        { icon: require("../assets/Events/dj2.png"), title: "DJ" },
        { icon: require("../assets/Events/magic.png"), title: "Magic Show" },
        { icon: require("../assets/Events/entertainment.png"), title: "Entertainment" },
        { icon: require("../assets/Events/vehicle.png"), title: "Vehicle arrangment" },
        { icon: require("../assets/Events/invitation.png"), title: "Invitation Cards" },
        { icon: require("../assets/Events/mehandi.png"), title: "Mehandi" },
        { icon: require("../assets/Events/costume.png"), title: "Costume Wear" },
        { icon: require("../assets/Events/photography.png"), title: "Photography" },
        { icon: require("../assets/Events/videography.png"), title: "Videography" },
        { icon: require("../assets/Events/balloon.png"), title: "Balloon Decoration" },
        { icon: require("../assets/Events/accomandation.png"), title: "Accomandation" },
      ];

    return(
        <div className="App">
        <Headerpage/>
        <div className="hero-event">
            <h1>Event Management</h1>
            <div className="button-group event">
                <button className="event-hero-button" onClick={() => navigate('/contact')}>Book Now</button>
            </div>
        </div>
        <div className="event-content">
            <h1>The AGE Event Management</h1>
            <img alt="eventBorders" src={eventBorder} className="eventBorder"></img>
            <p>
              With 15 years of excellence in event management in all over Tamil nadu, The 
              Age is commited to delivering customized, high-quality, and creative 
              solutions, ensuring complete client satisfaction with a specialized kit for 
              every event's unique needs.
            </p>
        </div>
        <div className="event-grid">
            {categories.map((category, index) => (
                <div className="event-item" key={index}>
                    <h3 className="event-title">{category.title}</h3>
                    <img src={category.image} alt={category.title} className="event-image" />
                </div>
            ))}
        </div>
        <div className="customized-event-container">
            <div className="customized-event-planning">
                <h2 className="title">Customized Event Plannings</h2>
                <div className="event-grid2">
                    {events.map((event, index) => (
                    <div className="event-item2" key={index}>
                        <div className="icon-container">
                        <img src={event.icon} alt={event.title} className="event-icon" />
                        </div>
                        <p className="event-title2">{event.title}</p>
                    </div>
                    ))}
                </div>
            </div>
        </div>
        <RecentActivity/>
        <Footerpage/>
        </div>
    );
}

export default Events;