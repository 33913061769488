import React from "react";
import "../styles/Contact.css";
import Headerpage from "./Header";
import Footerpage from "./Footer";
import RecentActivity from "./RecentActivity";
import BookingForm from "./BookingForm";
import email from "../assets/Contact/email.png";
import call from "../assets/Contact/call.png";
import location from "../assets/Contact/location.png";

const Contact = () => {

    return(
        <div className="App">
        <Headerpage activeValue={"contact"}/>
        <div className="hero-contact">
            <h1>Connect</h1>
            <p>Excited to collaborate and bring your visions to life</p>
        </div>
        <div className="contact-info-container">
            <div className="info-card line">
                <h3>CONTACT</h3>
                <div className="icon">
                    <img src={call} alt="Phone" className="call" />
                </div>
                <p>+91 81220 22225</p>
                <p>+91 78711 22225</p>
            </div>

            <div className="info-card">
                <h3>LOCATION</h3>
                <div className="icon">
                    <img src={location} alt="Location" className="location" />
                </div>
                <p>8, Badhmavathi Layout,</p>
                <p>Chinnavedampatty,</p>
                <p>Coimbatore - 641 049</p>
            </div>

            <div className="info-card">
                <h3>EMAIL</h3>
                <div className="icon">
                    <img src={email} alt="Email" className="email" />
                </div>
                <p>theage2025@gmail.com</p>
            </div>
            </div>
        <RecentActivity/>
        <BookingForm/>
        <Footerpage/>
        </div>
    );
}

export default Contact;